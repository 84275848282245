import { ApiServiceWithSettings, Audience, Authentication } from '../../common'
import { AxiosPromise } from 'axios'
import {
  DeliveryMethods,
  Orders,
  Pagination,
  Rfq,
  Account,
  Orderpath,
  QuantityTypeEnum,
  LegacyPager
} from '@one/types'
import qs from 'qs'
import { pickBy } from 'lodash-es'
import Pager = Pagination.Pager
import {
  Offers,
  Cart,
  CartPreview,
  Shared
} from '@one/types/dist/orderpath/backoffice'
import AvailablePaymentMethodsRestView = Cart.Responses.AvailablePaymentMethodsRestView
import CartPreviewDto = CartPreview.Responses.CartPreviewDto
import NewCartRestCommand = Cart.Requests.NewCartRestCommand
import CartSimpleRestView = Cart.Responses.CartSimpleRestView
import ChangeDeliveryDateRestCommand = Cart.Requests.ChangeDeliveryDateRestCommand
import AvailableDeliveryDatesRestView = Cart.Responses.AvailableDeliveryDatesRestView
import AddOrRemoveProductsRestCommand = Cart.Requests.AddOrRemoveProductsRestCommand
import BackofficeCampaignAwardsRestView = Cart.Responses.BackofficeCampaignAwardsRestView
import OfferStatusWithLabelRestView = Offers.Responses.OfferStatusWithLabelRestView
import ChangeProductLinePricesCommand = Cart.Requests.ChangeProductLinePricesCommand
import OfferListRestView = Offers.Responses.OfferListRestView
import NewOfferApplicationCommand = Offers.Requests.NewOfferApplicationCommand
import OfferRestView = Offers.Responses.OfferRestView
import NewOfferFromCartCommand = Offers.Requests.NewOfferFromCartCommand
import AddNoteRestCommand = Offers.Requests.AddNoteRestCommand
import AddNotesRestCommand = Offers.Requests.AddNotesRestCommand
import EditNoteRestCommand = Offers.Requests.EditNoteRestCommand
import OverrideDeliveryPriceRestCommand = Offers.Requests.OverrideDeliveryPriceRestCommand
import ApproveOfferRestCommand = Offers.Requests.ApproveOfferRestCommand
import NewOfferVersionRestCommand = Offers.Requests.NewOfferVersionRestCommand
import TransferOfferRestCommand = Offers.Requests.TransferOfferRestCommand
import RejectOfferRestCommand = Offers.Requests.RejectOfferRestCommand
import ChangeOfferExpirationDateRestCommand = Offers.Requests.ChangeOfferExpirationDateRestCommand
import SendOfferForValidationRestCommand = Offers.Requests.SendOfferForValidationRestCommand
import CopyOfferRestCommand = Offers.Requests.CopyOfferRestCommand
import AddProductToOffer = Offers.Requests.AddProductToOffer
import OfferPrinterRestView = Offers.Responses.OfferPrinterRestView
import EditPromoPointsRestCommand = Orderpath.Backoffice.Cart.Requests.EditPromoPointsRestCommand
import ChangeProductLinesOrderRestCommand = Orderpath.Backoffice.Cart.Requests.ChangeProductLinesOrderRestCommand

export class BackofficeOrderpathApi extends ApiServiceWithSettings {
  wishlistsPreview(
    clientId: string,
    offset: number,
    rows: number
  ): AxiosPromise<
    Orderpath.Backoffice.WishlistPreview.Responses.WishlistPager
  > {
    return this.get('/wishlist-preview', {
      params: {
        clientId,
        offset,
        rows
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      cache: {
        ttl: 60 * 100,
        tags: ['wishlists-bo']
      }
    })
  }

  wishlistPreview(
    wishlistId: string
  ): AxiosPromise<Orderpath.Backoffice.WishlistPreview.WishListPreviewDto> {
    return this.get(`/wishlist-preview/${wishlistId}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      cache: {
        ttl: 60 * 100,
        tags: ['wishlists-bo']
      }
    })
  }

  getOrderSettingsNumber(): AxiosPromise<
    Orders.Backoffice.OrderNumberTemplateDto
  > {
    return this.get('/order-number', {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  updateOrderSettingsNumber(
    data: Orders.Backoffice.OrderNumberTemplateDto
  ): void | AxiosPromise<Orders.Backoffice.OrderNumberTemplateSetFailure> {
    return this.put('/order-number', data, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getVariablesOrderSettingsNumber(): AxiosPromise<
    Orders.Backoffice.OrderNumberTemplateVariables
  > {
    return this.get('/order-number/variables', {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  /**
   * @deprecated The method moved to 'orders' service
   */
  getOrders(
    pageNumber: number,
    pageSize: number,
    clientId: string | null,
    search: string | null,
    shipmentStatuses: Array<string> | null
  ): AxiosPromise<Pagination.Pager<Orders.Backoffice.BackofficeOrder>> {
    return this.get('/order', {
      params: pickBy({
        pageNumber,
        pageSize,
        clientId,
        search,
        shipmentStatuses
      }),
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  /**
   * @deprecated The method moved to 'orders' service
   */
  getOrderShipmentStatuses(): AxiosPromise<Array<Orders.OrderStatusDto>> {
    return this.get('/order-status', {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      cache: {
        tags: ['order-status']
      }
    })
  }

  /**
   * @deprecated The method moved to 'orders' service
   */
  getOrderDetail(orderId: string): AxiosPromise<Orders.DetailedOrder.Order> {
    return this.get(`/order/${orderId}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getOfflineOrders(
    pageNumber: string,
    pageSize: string,
    clientId: string | null,
    search: string | null,
    startDate: string | null,
    endDate: string | null
  ): AxiosPromise<Orders.Backoffice.OfflineOrderPager> {
    return this.get('/offline-order-v2', {
      params: {
        pageNumber,
        pageSize,
        clientId,
        search,
        startDate,
        endDate
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      cache: {
        tags: ['offline-order']
      }
    })
  }

  getOfflineOrderDetail(
    orderId: string
  ): AxiosPromise<Orders.Backoffice.BackofficeOfflineOrder> {
    return this.get('/offline-order/single', {
      params: {
        orderId
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getRfqs(): AxiosPromise<Rfq.Responses.Backoffice.RfqSettingsDto> {
    return this.get('/rfq', {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      cache: {
        ttl: 60 * 100,
        tags: ['rfq']
      }
    })
  }

  deleteRfqValue(name: string): AxiosPromise<void> {
    this.invalidate(['rfq'])
    return this.delete(`/rfq/${name}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  updateRfq(
    name: string,
    data: number | string | Array<string>,
    value: string
  ): AxiosPromise<void> {
    this.invalidate(['rfq'])
    return this.put(`/rfq/${name}`, null, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      params: {
        [value]: data
      },
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    })
  }

  getCartSplits(): AxiosPromise<
    Array<Orderpath.Backoffice.SplitRules.Responses.SplitRule>
  > {
    return this.get('/split-rules', {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      cache: {
        ttl: 60 * 100,
        tags: ['splits']
      }
    })
  }

  getCartSplit(
    splitRuleId: string
  ): AxiosPromise<Orderpath.Backoffice.SplitRules.Responses.SplitRule> {
    return this.get(`/split-rules/${splitRuleId}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      cache: {
        ttl: 60 * 100,
        tags: ['split']
      }
    })
  }

  createCartSplit(
    data: Orderpath.Backoffice.SplitRules.Requests.SplitRuleRequest
  ): AxiosPromise<string> {
    this.invalidate(['splits'])
    return this.post('/split-rules', data, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  updateCartSplit(
    data: Orderpath.Backoffice.SplitRules.Requests.SplitRuleRequest,
    splitRuleId: string
  ): AxiosPromise<void> {
    this.invalidate(['splits'])
    return this.patch(`/split-rules/${splitRuleId}`, data, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  deleteCartSplit(splitRuleId: string): AxiosPromise<void> {
    this.invalidate(['splits'])
    return this.delete(`/split-rules/${splitRuleId}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  /**
   * @deprecated The method moved to 'orders' service
   */
  getShipmentsStatus(): AxiosPromise<
    Array<Orders.Backoffice.Response.ShipmentStatus>
  > {
    return this.get('/shipment-status', {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      cache: {
        ttl: 60 * 100,
        tags: ['shipmentStatus']
      }
    })
  }

  /**
   * @deprecated The method moved to 'orders' service
   */
  createShipmentStatus(
    status: Orders.Backoffice.Request.ShipmentStatusRequest
  ): AxiosPromise<void> {
    this.invalidate(['shipmentStatus'])
    return this.post('/shipment-status', status, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  /**
   * @deprecated The method moved to 'orders' service
   */
  deleteShipmentStatus(shipmentStatusId: string): AxiosPromise<void> {
    this.invalidate(['shipmentStatus'])
    return this.delete(`/shipment-status/${shipmentStatusId}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  /**
   * @deprecated The method moved to 'orders' service
   */
  updateShipmentStatus(
    shipmentStatusId: string,
    status: Orders.Backoffice.Request.ShipmentStatusRequest
  ): AxiosPromise<void> {
    this.invalidate(['shipmentStatus'])
    return this.patch(`/shipment-status/${shipmentStatusId}`, status, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getDeliveryMethodVat(): AxiosPromise<Orderpath.Backoffice.Vat.Responses.VAT> {
    return this.get('/vat/delivery-method', {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      cache: {
        ttl: 60 * 100,
        tags: ['delivery-method-vat']
      }
    })
  }

  updateDeliveryMethodVat(
    data: Orderpath.Backoffice.Vat.Requests.SetVATOption
  ): AxiosPromise<void> {
    this.invalidate(['delivery-method-vat'])
    return this.put(`/vat/delivery-method`, data, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  deleteDeliveryMethodVat(): AxiosPromise<void> {
    this.invalidate(['delivery-method-vat'])
    return this.delete(`/vat/delivery-method`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getNonWorkingDays(month: number, year: number): AxiosPromise<Array<number>> {
    return this.get(`/working-days-calendar/non-working-days`, {
      params: {
        month,
        year
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  setNonWorkingDays(
    isWorking: boolean = false,
    day: number,
    month: number,
    year: number
  ): AxiosPromise<void> {
    return this.put(
      'working-days-calendar/set-day',
      {},
      {
        params: {
          isWorking,
          day,
          month,
          year
        },
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  getRfqList(
    clientId: string | null,
    endDate: string | null,
    pageNumber: number,
    search: string | null,
    pageSize: number,
    startDate: string | null
  ): AxiosPromise<Pagination.Pager<Rfq.Responses.Backoffice.RfqCart>> {
    return this.get('/rfq-cart', {
      params: {
        clientId,
        endDate,
        pageNumber,
        search,
        pageSize,
        startDate
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  /**
   * Get shippment size for Backoffice
   */
  getShippingSize(): AxiosPromise<
    Orderpath.Backoffice.ShippingSize.Responses.ShippingSize
  > {
    return this.get('/shipping-size', {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      cache: {
        ttl: 60 * 100,
        tags: ['shipping-sizes-bo']
      }
    })
  }

  createShippingSize(
    data: Orderpath.Backoffice.ShippingSize.Responses.ShippingSize
  ): AxiosPromise<void> {
    this.invalidate(['shipping-sizes-bo'])
    return this.post('/shipping-size', data, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  updateShippingSize(
    shippingSizeId: string,
    data: Orderpath.Backoffice.ShippingSize.Responses.ShippingSize
  ): AxiosPromise<void> {
    this.invalidate(['shipping-sizes-bo'])
    return this.patch(`/shipping-size/${shippingSizeId}`, data, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  deleteShippingSize(shippingSizeId: string): AxiosPromise<void> {
    this.invalidate(['shipping-sizes-bo'])
    return this.delete(`/shipping-size/${shippingSizeId}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getDeliveryMethods(): AxiosPromise<
    Array<Orderpath.Backoffice.DeliveryMethods.Responses.DeliveryMethod>
  > {
    return this.get('/delivery-methods', {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      cache: {
        ttl: 60 * 100,
        tags: ['delivery-methods-bo']
      }
    })
  }

  createDeliveryMethod(
    deliveryType: string,
    data: Orderpath.Backoffice.DeliveryMethods.Responses.DeliveryMethod
  ): AxiosPromise<void> {
    this.invalidate(['delivery-methods-bo'])
    return this.post(`/delivery-methods/${deliveryType}`, data, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  updateDeliveryMethod(
    deliveryType: string,
    deliveryId: string,
    data:
      | Orderpath.Backoffice.DeliveryMethods.Requests.DeliveryMethodRequest
      | Orderpath.Backoffice.DeliveryMethods.Requests.WarehouseDeliveryMethodRequest
  ): AxiosPromise<void> {
    this.invalidate(['delivery-methods-bo'])
    return this.patch(`/delivery-methods/${deliveryType}/${deliveryId}`, data, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  deleteDeliveryMethod(deliveryId: string): AxiosPromise<void> {
    this.invalidate(['delivery-methods-bo'])
    return this.delete(`/delivery-methods/${deliveryId}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getPaymentMethods(): AxiosPromise<AvailablePaymentMethodsRestView> {
    return this.get('/payment-methods', {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      cache: {
        ttl: 60 * 100,
        tags: ['payment-methods-bo']
      }
    })
  }

  deletePaymentMethod(paymentMethodId: string): AxiosPromise<void> {
    this.invalidate(['payment-methods-bo'])
    return this.delete(`/payment-methods/${paymentMethodId}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  exportProductsFromCart(cartId: string): AxiosPromise<string> {
    return this.get(`/carts/${cartId}/export`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      cache: {
        tags: ['carts', 'cart']
      },
      responseType: 'blob'
    })
  }

  getExampleImportFile(extension: string): AxiosPromise<string> {
    return this.get(`/carts/example-import-file`, {
      authentication: Authentication.REQUIRED,
      params: {
        extension
      },
      aud: Audience.BACKOFFICE,
      cache: {
        tags: ['carts', 'cart']
      },
      responseType: 'blob'
    })
  }

  importProductsToCart(
    formData: FormData,
    cartId: string
  ): AxiosPromise<Shared.ImportErrorRestView | []> {
    this.invalidate(['carts'])
    return this.put(`/carts/${cartId}/import`, formData, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  cartsPreview(
    clientId: string,
    offset: number,
    rows: number
  ): AxiosPromise<LegacyPager<CartPreviewDto>> {
    return this.get('/cart-preview', {
      params: {
        clientId,
        offset,
        rows
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      cache: {
        ttl: 60 * 100,
        tags: ['carts-bo']
      }
    })
  }

  cartPreview(cartId: string): AxiosPromise<CartPreviewDto> {
    return this.get(`/cart-preview/${cartId}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      cache: {
        ttl: 60 * 100,
        tags: ['carts-bo']
      }
    })
  }

  getRfqDetail(
    rfqNegotiationId: string
  ): AxiosPromise<Rfq.Responses.Backoffice.DetailedRfqCart> {
    return this.get(`/rfq-cart/temp/negotiation/${rfqNegotiationId}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      cache: {
        tags: ['rfq-detail']
      }
    })
  }

  createEmptyCart(data: NewCartRestCommand): AxiosPromise<void> {
    this.invalidate(['carts-offline-bo'])
    return this.post('/carts', data, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  // offline carts
  getCarts(
    clientId: string,
    params: Cart.Requests.GetCartsRequestParams
  ): AxiosPromise<Pager<CartSimpleRestView>> {
    return this.get(`/carts`, {
      params: pickBy({
        ...params,
        clientId
      }),
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      cache: {
        tags: ['carts-offline-bo']
      }
    })
  }

  getCartView(cartId: string): AxiosPromise<Shared.CartRestView> {
    this.invalidate(['carts-offline-bo'])
    return this.get(`/carts/${cartId}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      cache: {
        tags: ['carts-offline-bo'],
        ttl: 5000
      }
    })
  }

  getCartForm(cartId: string): AxiosPromise<Shared.CartForm> {
    this.invalidate(['carts-offline-bo'])
    return this.get(`/carts/${cartId}/form`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      cache: {
        tags: ['carts-offline-bo'],
        ttl: 5000
      }
    })
  }

  updateCartForm(
    cartId: string,
    customAttributes: Array<{ name: string; value: string | number | boolean }>
  ): AxiosPromise {
    this.invalidate(['carts-offline-bo'])
    return this.put(
      `/carts/${cartId}/custom-attributes`,
      {
        customAttributes
      },
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  addProductToCart(
    cartId: string,
    productId: string,
    quantity: number,
    type: QuantityTypeEnum
  ): AxiosPromise {
    this.invalidate(['carts-offline-bo'])
    return this.put(
      `/carts/${cartId}/add-product`,
      {
        productId,
        quantity: {
          type,
          value: quantity
        }
      },
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }
  setWarehouseContext(cartId: string, warehouseId: string): AxiosPromise<void> {
    this.invalidate(['carts-offline-bo'])
    return this.put(
      `/carts/${cartId}/context-warehouse`,
      {
        warehouseId
      },
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  changeDeliveryDate(
    cartId: string,
    data: ChangeDeliveryDateRestCommand
  ): AxiosPromise<void> {
    this.invalidate(['carts-offline-bo'])
    return this.put(`/carts/${cartId}/delivery-date`, data, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getAvailableDeliveryDates(
    cartId: string,
    deliveryMethodId: string
  ): AxiosPromise<AvailableDeliveryDatesRestView> {
    return this.get(
      `/carts/${cartId}/delivery-methods/${deliveryMethodId}/delivery-dates`,
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  setCouponCode(cartId: string, couponCode: string): AxiosPromise<void> {
    this.invalidate(['carts-offline-bo'])
    return this.put(
      `/carts/${cartId}/coupon-code`,
      {
        couponCode
      },
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  setPurchaseOrderNumber(
    cartId: string,
    purchaseOrderNumber: string
  ): AxiosPromise<void> {
    return this.put(
      `/carts/${cartId}/purchase-order-number`,
      {
        purchaseOrderNumber
      },
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  removePurchaseOrderNumber(cartId: string): AxiosPromise<void> {
    return this.delete(`/carts/${cartId}/purchase-order-number`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  changeProductLineQuantity(
    cartId: string,
    productLineId: string,
    quantity: number
  ): AxiosPromise<void> {
    this.invalidate(['carts-offline-bo'])
    return this.put(
      `/carts/${cartId}/product-line-quantity`,
      {
        productLineId,
        quantity
      },
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  removeCouponCode(cartId: string): AxiosPromise<void> {
    this.invalidate(['carts-offline-bo'])
    return this.delete(`/carts/${cartId}/coupon-code`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  removeProductLines(
    cartId: string,
    productLineIds: string
  ): AxiosPromise<void> {
    this.invalidate(['carts-offline-bo'])
    return this.delete(`/carts/${cartId}/product-line`, {
      data: {
        productLineIds
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  setPromoPoints(
    cartId: string,
    payload: EditPromoPointsRestCommand
  ): AxiosPromise<void> {
    return this.put(`/carts/${cartId}/promo-points`, payload, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  removePromoPoints(cartId: string): AxiosPromise<void> {
    return this.delete(`/carts/${cartId}/promo-points`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getCartPaymentMethods(
    cartId: string
  ): AxiosPromise<Array<AvailablePaymentMethodsRestView>> {
    return this.get(`/carts/${cartId}/payment-methods`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  setCartPaymentMethod(
    cartId: string,
    paymentMethodId: string
  ): AxiosPromise<void> {
    this.invalidate(['carts-offline-bo'])
    return this.put(
      `/carts/${cartId}/payment-method`,
      {
        paymentMethodId
      },
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  getCartDeliveryMethods(
    cartId: string,
    slotId: string
  ): AxiosPromise<Array<AvailableDeliveryDatesRestView>> {
    return this.get(`carts/${cartId}/delivery-methods/${slotId}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  setSlotDeliveryMethod(
    cartId: string,
    deliveryMethodId: string,
    slotId: string
  ): AxiosPromise<void> {
    this.invalidate(['carts-offline-bo'])
    return this.put(
      `/carts/${cartId}/delivery-method`,
      {
        deliveryMethodId,
        slotId
      },
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  setSlotParcelLocker(
    cartId: string,
    data: DeliveryMethods.Requests.AssignDeliveryParcelLockerRestCommand
  ): AxiosPromise<void> {
    this.invalidate(['carts-offline-bo'])
    return this.put(`/carts/${cartId}/delivery-parcel-locker`, data, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  setSlotExistingDeliveryAddress(
    cartId: string,
    addressId: string,
    slotId: string
  ): AxiosPromise<void> {
    this.invalidate(['carts-offline-bo'])
    return this.put(
      `/carts/${cartId}/address-book-delivery-address`,
      {
        addressId,
        slotId
      },
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  setSlotNewDeliveryAddress(
    cartId: string,
    slotId: string,
    address: Account.Backoffice.BillingAddress
  ): AxiosPromise<void> {
    this.invalidate(['carts-offline-bo'])
    return this.put(
      `/carts/${cartId}/inline-delivery-address`,
      {
        slotId,
        address
      },
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  assignUsersToSlot(
    cartId: string,
    slotId: string,
    userIds: Array<string>
  ): AxiosPromise<void> {
    this.invalidate(['carts-offline-bo'])
    return this.put(
      `/carts/${cartId}/assign-authorized-users-to-slot`,
      {
        slotId,
        userIds
      },
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  assignInlineUsersToSlot(
    cartId: string,
    slotId: string,
    name: string,
    email: string,
    phone: string
  ): AxiosPromise<void> {
    this.invalidate(['carts-offline-bo'])
    return this.put(
      `/carts/${cartId}/assign-inline-authorized-person-to-slot`,
      {
        slotId,
        name,
        email,
        phone
      },
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  removeUserFromSlot(
    cartId: string,
    slotId: string,
    authorizedPersonIds: Array<string>
  ): AxiosPromise<void> {
    this.invalidate(['carts-offline-bo'])
    return this.delete(`/carts/${cartId}/remove-authorized-persons-from-slot`, {
      data: {
        slotId,
        authorizedPersonIds
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  refreshCart(cartId: string): AxiosPromise<void> {
    this.invalidate(['carts-offline-bo'])
    return this.put(
      `/carts/${cartId}/refresh`,
      {},
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  toggleAvailibilitySplit(cartId: string, status: boolean): AxiosPromise<void> {
    this.invalidate(['carts-offline-bo'])
    return this.put(
      `/carts/${cartId}/toggle-splitting-by-availibility`,
      {
        splitByAvailability: status
      },
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  /**
   * @deprecated Will be remove in future
   */
  createOrder(
    command: Orders.Backoffice.Request.CreateOrderFromCartRestCommand
  ): AxiosPromise<void> {
    this.invalidate(['carts-offline-bo'])
    return this.post(`/orders/from-cart`, command, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  createOrderV2(
    command: Orders.Backoffice.Request.CreateOrderFromCartRestCommand
  ): AxiosPromise<Orders.Backoffice.Response.OrderResultRestView> {
    this.invalidate(['carts-offline-bo'])
    return this.post(`/orders/from-cart-v2`, command, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  setSlotWarehouseDeliveryMethod(
    cartId: string,
    warehouseId: string,
    slotId: string
  ): AxiosPromise<void> {
    this.invalidate(['carts-offline-bo'])
    return this.put(
      `/carts/${cartId}/delivery-method-warehouse`,
      {
        warehouseId,
        slotId
      },
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  transferCart(cartId: string, clientId: string): AxiosPromise<void> {
    this.invalidate(['carts-offline-bo'])
    return this.put(
      `/carts/${cartId}/transfer`,
      {
        clientId
      },
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  copyCart(cartId: string, clientId: string): AxiosPromise<void> {
    this.invalidate(['carts-offline-bo'])
    return this.put(
      `/carts/${cartId}/copy`,
      {
        clientId
      },
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }
  deleteCarts(cartIds: string): AxiosPromise<void> {
    this.invalidate(['carts-offline-bo'])
    return this.delete(`/carts`, {
      data: {
        cartIds
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  selectAwards(
    cartId: string,
    changedAwards: AddOrRemoveProductsRestCommand
  ): AxiosPromise<void> {
    this.invalidate(['carts-offline-bo'])
    return this.post(`/carts/${cartId}/products/add-or-remove`, changedAwards, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getCampaignAwardsInCart(
    cartId: string,
    campaignId: string
  ): AxiosPromise<BackofficeCampaignAwardsRestView> {
    return this.get(`carts/${cartId}/campaigns/${campaignId}/awards`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  createReservation(cartId: string): AxiosPromise<void> {
    this.invalidate(['carts-offline-bo'])
    return this.post(`/carts/${cartId}/reservation`, null, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  refreshReservation(cartId: string): AxiosPromise<void> {
    this.invalidate(['carts-offline-bo'])
    return this.put(`/carts/${cartId}/reservation`, null, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  deleteReservation(cartId: string): AxiosPromise<void> {
    this.invalidate(['carts-offline-bo'])
    return this.delete(`/carts/${cartId}/reservation`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getOfferStatuses(): AxiosPromise<OfferStatusWithLabelRestView> {
    return this.get('/offer-statuses', {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  updateOfferStatusLabel(
    label: string,
    offerStatusType: Shared.OfferStatusType
  ): AxiosPromise<void> {
    return this.post(`/offer-statuses/${offerStatusType}/update-label`, null, {
      params: {
        label
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  changePrice(
    cartId: string,
    payload: ChangeProductLinePricesCommand
  ): AxiosPromise<void> {
    return this.post(`/carts/${cartId}/products/change-price`, payload, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  priceCalculation(
    payload: Orderpath.Backoffice.Price.Requests.RecalculateOverrideFormApplicationCommand
  ): AxiosPromise<Array<Shared.OverrideFormRestView>> {
    return this.post('/prices/recalculate-change', payload, {
      authentication: Authentication.PUBLIC,
      aud: Audience.BACKOFFICE
    })
  }

  applyCalculationsForm(
    payload: Orderpath.Backoffice.Price.Requests.ApplyPriceCalculationFormCommand
  ): AxiosPromise {
    return this.post('/prices/calculations/apply-form', payload, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  createCalculationsForm(
    payload: Orderpath.Backoffice.Price.Requests.CreateCalculationFormCommand
  ): AxiosPromise<
    Orderpath.Backoffice.Price.Response.CreatePriceCalculationFormResponse
  > {
    return this.post('/prices/calculations/create-form', payload, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  recalculateCalculationsForm(
    payload: Orderpath.Backoffice.Price.Requests.PriceRecalculationCommand
  ): AxiosPromise<
    Orderpath.Backoffice.Price.Response.PriceRecalculationResponse
  > {
    return this.post('/prices/calculations/recalculate-form', payload, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getOffers(
    pageNumber: number,
    pageSize: number,
    search: string,
    status: Shared.OfferStatusType,
    previousAssignee: string,
    byAssignedTo: string,
    byCreatedBy: string,
    clientId: string,
    createdOnStartDate: string,
    createdOnEndDate: string,
    expirationDateStartDate: string,
    expirationDateEndDate: string,
    branchId: string
  ): AxiosPromise<Pagination.Pager<OfferListRestView>> {
    return this.get('/offers', {
      params: {
        pageNumber,
        pageSize,
        search,
        status,
        previousAssignee,
        byAssignedTo,
        byCreatedBy,
        clientId,
        createdOnStartDate,
        createdOnEndDate,
        expirationDateStartDate,
        expirationDateEndDate,
        branchId
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  createNewOffer(payload: NewOfferApplicationCommand): AxiosPromise<string> {
    return this.post('/offers', payload, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getOffer(offerId: string): AxiosPromise<OfferRestView> {
    return this.get(`/offers/${offerId}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  deleteOffer(offerId: string): AxiosPromise<void> {
    return this.delete(`/offers/${offerId}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  createNewOfferByCart(payload: NewOfferFromCartCommand): AxiosPromise<string> {
    return this.post('/offers/from-cart', payload, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  addNoteToOffer(
    command: AddNoteRestCommand,
    offerId: string
  ): AxiosPromise<string> {
    return this.post(`/offers/${offerId}/notes`, command, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  addNotesToOffer(
    command: AddNotesRestCommand,
    offerId: string
  ): AxiosPromise<Array<string>> {
    return this.post(`/offers/${offerId}/notes/multiple`, command, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  editNoteToOffer(
    command: EditNoteRestCommand,
    noteId: string,
    offerId: string
  ): AxiosPromise<void> {
    return this.put(`/offers/${offerId}/notes/${noteId}`, command, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  deleteNoteToOffer(noteId: string, offerId: string): AxiosPromise<void> {
    return this.delete(`/offers/${offerId}/notes/${noteId}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  overrideOfferPrice(
    offerId: string,
    splitSlotId: string,
    payload: OverrideDeliveryPriceRestCommand
  ): AxiosPromise<void> {
    return this.post(
      `/offers/${offerId}/delivery/${splitSlotId}/override-price`,
      payload,
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  resetOverriddenOfferPrice(
    offerId: string,
    splitSlotId: string
  ): AxiosPromise<void> {
    return this.put(
      `/offers/${offerId}/delivery/${splitSlotId}/reset-overridden-price`,
      null,
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  togglePointOfSaleMode(cartId: string, pointOfSaleMode: boolean) {
    this.invalidate(['carts-offline-bo'])
    return this.put(
      `/carts/${cartId}/point-of-sale-mode`,
      { pointOfSaleMode },
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  sendOfferToClient(offerId: string, userId?: string): AxiosPromise<void> {
    return this.put(
      `/offers/${offerId}/send-offer-to-client`,
      pickBy({
        userId
      }),
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  approveOffer(
    command: ApproveOfferRestCommand,
    offerId: string
  ): AxiosPromise<void> {
    return this.put(`/offers/${offerId}/approve`, command, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  markOfferAsWon(offerId: string): AxiosPromise<void> {
    return this.put(
      `/offers/${offerId}/mark-as-won`,
      {},
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  getOfferVersionsList(
    offerId: string
  ): AxiosPromise<Array<OfferListRestView>> {
    return this.get(`/offers/${offerId}/versions`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getOfferVersion(
    offerId: string,
    version: number
  ): AxiosPromise<OfferRestView> {
    return this.get(`/offers/${offerId}/versions/${version}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  createNewOfferVersion(
    command: NewOfferVersionRestCommand,
    offerId: string
  ): AxiosPromise<void> {
    return this.post(`/offers/${offerId}/new-version`, command, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  editShipmentNotes(
    cartId: string,
    editShipmentNotesRestCommand: Orders.Backoffice.Request.EditShipmentNotesRestCommand
  ): AxiosPromise<void> {
    return this.put(
      `/carts/${cartId}/shipment-notes`,
      editShipmentNotesRestCommand,
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  transferOfferToOperator(
    command: TransferOfferRestCommand,
    offerId: string
  ): AxiosPromise<void> {
    return this.put(`/offers/${offerId}/transfer`, command, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  cancelOffer(offerId: string): AxiosPromise<void> {
    return this.put(
      `/offers/${offerId}/cancel`,
      {},
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  rejectOffer(
    command: RejectOfferRestCommand,
    offerId: string
  ): AxiosPromise<void> {
    return this.put(`/offers/${offerId}/reject`, command, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  changeOfferExpirationDate(
    command: ChangeOfferExpirationDateRestCommand,
    offerId: string
  ): AxiosPromise<void> {
    return this.put(`/offers/${offerId}/change-expiration-date`, command, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  /**
   * @deprecated Will be remove in future
   */
  createOrderFromOffer(
    command: Orders.Backoffice.Request.CreateOrderFromOfferRestCommand
  ): AxiosPromise<void> {
    return this.post(`/orders/from-offer`, command, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  createOrderFromOfferV2(
    command: Orders.Backoffice.Request.CreateOrderFromOfferRestCommand
  ): AxiosPromise<Orders.Backoffice.Response.OrderResultRestView> {
    return this.post(`/orders/from-offer-v2`, command, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  sendOfferForValidation(
    command: SendOfferForValidationRestCommand,
    offerId: string
  ): AxiosPromise<void> {
    return this.put(`/offers/${offerId}/send-for-validation`, command, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  copyOffer(
    command: CopyOfferRestCommand,
    offerId: string
  ): AxiosPromise<void> {
    return this.post(`/offers/${offerId}/copy`, command, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  takeoverOffer(offerId: string): AxiosPromise<void> {
    return this.put(`/offers/${offerId}/takeover`, null, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  addProductToOffer(
    addProductToOffer: AddProductToOffer,
    offerId: string
  ): AxiosPromise<void> {
    return this.put(`/offers/${offerId}/add-product`, addProductToOffer, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  changeOfferProductLineQuantity(
    offerId: string,
    productLineId: string,
    quantity: number
  ): AxiosPromise<void> {
    return this.put(
      `/offers/${offerId}/product-line-quantity`,
      {
        productLineId,
        quantity
      },
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  removeOfferProductLines(
    offerId: string,
    productLineIds: Array<string>
  ): AxiosPromise<void> {
    return this.delete(`/offers/${offerId}/product-line`, {
      data: {
        productLineIds
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  changeOfferProductPrice(
    offerId: string,
    payload: Offers.Requests.OverrideOfferProductLinePricesRestCommand
  ): AxiosPromise<void> {
    return this.post(`/offers/${offerId}/products/change-price`, payload, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  markOfferAsLost(offerId: string): AxiosPromise<void> {
    return this.put(
      `/offers/${offerId}/mark-as-lost`,
      {},
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }
  triggerProductActivation(
    offerId: string,
    productId: string
  ): AxiosPromise<void> {
    return this.post(
      `/offers/${offerId}/activate-product-line`,
      {
        productId
      },
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }
  getOfferPdf(
    offerId: string,
    version: number,
    templateId: number
  ): AxiosPromise<OfferPrinterRestView> {
    return this.get(`/offers/${offerId}/print`, {
      params: {
        version,
        templateId
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  transferToExternalSystem(offerId: string): AxiosPromise<void> {
    return this.post(`offers/${offerId}/transfer-to-external-system`, null, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  setCustomAttributes(
    offerId: string,
    offerVersion: number,
    attributes: Array<{ name: string; value: string | number | boolean }>
  ): AxiosPromise<void> {
    return this.put(
      `/offers/${offerId}/${offerVersion}/custom-attributes`,
      {
        customAttributes: attributes
      },
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  getCustomAttributesForm(
    offerId: string,
    offerVersion: number
  ): AxiosPromise<{ fields: unknown | {} }> {
    return this.get(`/offers/${offerId}/${offerVersion}/form`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  assigneeUserToCart(cartId: string, userId: string): AxiosPromise<void> {
    return this.put(
      `/carts/${cartId}/assignee-user`,
      { userId },
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  assigneeOperatorToCart(
    cartId: string,
    operatorId: string
  ): AxiosPromise<void> {
    return this.put(
      `/carts/${cartId}/assignee-operator`,
      { operatorId },
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  changeCartProductLineOrder(
    cartId: string,
    payload: ChangeProductLinesOrderRestCommand
  ): AxiosPromise<void> {
    return this.post(`/carts/${cartId}/change-product-lines-order`, payload, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getManualCampaigns(
    cartId: string
  ): AxiosPromise<Orderpath.Backoffice.Shared.AvailableCampaignRestView> {
    return this.get(`/carts/${cartId}/campaigns/manually-selectable`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  updateManualCampaigns(
    cartId: string,
    campaigns: Array<string>
  ): AxiosPromise<void> {
    return this.put(
      `/carts/${cartId}/campaigns/manually-selectable`,
      { campaigns },
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }
}
