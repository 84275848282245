import { ApiService, Authentication } from '../../common'
import { AxiosPromise } from 'axios'
import {
  Checkout,
  DeliveryMethods,
  Orders,
  PaymentMethod,
  Responses,
  Rfq,
  Orderpath
} from '@one/types'
import {
  Offers,
  Cart,
  Shared,
  Wishlist,
  OrderConfirmation
} from '@one/types/dist/orderpath/app'
import qs from 'qs'
import WishlistId = Responses.WishlistId
import EditPromoPointsRestCommand = Orderpath.App.Cart.Requests.EditPromoPointsRestCommand

export class AppOrderpathApi extends ApiService {
  getWishlists(): AxiosPromise<Array<Wishlist.Responses.BasicWishlist>> {
    return this.get('/wish-list', {
      authentication: Authentication.REQUIRED,
      cache: {
        tags: ['wishlists']
      }
    })
  }

  createWishlist(
    name: Wishlist.Requests.CreateWishlistOption
  ): AxiosPromise<WishlistId> {
    this.invalidate('wishlists')
    return this.post('/wish-list', name, {
      authentication: Authentication.OPTIONAL
    })
  }

  renameWishlist(
    wishlistId: string,
    name: Wishlist.Requests.ChangeWithListNameOption
  ): AxiosPromise<void> {
    this.invalidate('wishlists')
    return this.patch(`/wish-list/${wishlistId}/name`, name, {
      authentication: Authentication.OPTIONAL
    })
  }

  getWishlist(
    wishlistId: string,
    warehouseId: string
  ): AxiosPromise<Wishlist.Responses.Wishlist> {
    return this.get(`/wish-list/${wishlistId}`, {
      authentication: Authentication.OPTIONAL,
      params: {
        warehouseId
      },
      cache: {
        tags: ['wishlists', 'wishlist']
      }
    })
  }

  getAnonymousWishlist(
    wishlistId: string,
    warehouseId: string
  ): AxiosPromise<Wishlist.Responses.Wishlist> {
    return this.get(`/wish-list/${wishlistId}`, {
      authentication: Authentication.OPTIONAL,
      injectToken: false,
      params: {
        warehouseId
      }
    })
  }

  removeWishlist(wishlistId: string): AxiosPromise<void> {
    this.invalidate('wishlists')
    return this.delete(`/wish-list/${wishlistId}`, {
      authentication: Authentication.OPTIONAL
    })
  }

  removeAnonymousWishlist(wishlistId: string): AxiosPromise<void> {
    return this.delete(`/wish-list/${wishlistId}`, {
      authentication: Authentication.OPTIONAL,
      injectToken: false
    })
  }

  convertToCart(wishlistId: string): AxiosPromise<Cart.Responses.NewCart> {
    this.invalidate(['wishlists', 'carts'])
    return this.post(`/wish-list/${wishlistId}/convert-to-cart`, {
      authentication: Authentication.REQUIRED
    })
  }

  copyToCart(
    wishlistId: string,
    cartId: string
  ): AxiosPromise<Wishlist.Requests.CopyWishlistToCartOption> {
    this.invalidate(['wishlists', 'carts'])
    return this.post(`/wish-list/${wishlistId}/copy-to-cart`, cartId, {
      authentication: Authentication.OPTIONAL
    })
  }

  changeWishlistProductOrder(
    wishlistId: string,
    newOrder: Wishlist.Requests.ChangeOrderOfProductsOption
  ): AxiosPromise<void> {
    this.invalidate(['wishlists'])
    return this.put(`/wish-list/${wishlistId}/order`, newOrder, {
      authentication: Authentication.OPTIONAL
    })
  }

  addProductsToWishlist(
    wishlistId: string,
    newProduct: Wishlist.Requests.ChangeNumberOfProductInWishlistOption
  ): AxiosPromise<void> {
    this.invalidate(['wishlists'])
    return this.post(`/wish-list/${wishlistId}/products`, newProduct, {
      authentication: Authentication.OPTIONAL
    })
  }

  setWishlistProductNumber(
    wishlistId: string,
    newAmount: Wishlist.Requests.ChangeNumberOfProductInWishlistOption
  ): AxiosPromise<void> {
    this.invalidate(['wishlists'])
    return this.put(`/wish-list/${wishlistId}/products`, newAmount, {
      authentication: Authentication.OPTIONAL
    })
  }

  removeWishlistProduct(
    wishlistId: string,
    productId: Wishlist.Requests.RemoveProductFromWithListOption
  ): AxiosPromise<void> {
    this.invalidate(['wishlists'])
    return this.delete(`/wish-list/${wishlistId}/products`, {
      data: {
        productId
      },
      authentication: Authentication.OPTIONAL
    })
  }

  takeoverWishlist(wishlistId: string): AxiosPromise<WishlistId> {
    this.invalidate(['wishlists'])
    return this.post(
      `/wish-list/${wishlistId}/takeover`,
      {},
      {
        authentication: Authentication.REQUIRED
      }
    )
  }

  /**
   * @deprecated The method moved to 'orders' service
   */
  getOrdersList(
    start: number,
    rows: number,
    search: string | null,
    startDate: string | null,
    endDate: string | null,
    shipmentStatuses: Array<string> | null
  ): AxiosPromise<Orders.Responses.OrderPagination> {
    return this.get('/order', {
      params: {
        start: start,
        rows: rows,
        search: search || null,
        startDate: startDate ? startDate : null,
        endDate: endDate ? endDate : null,
        shipmentStatuses: shipmentStatuses ? shipmentStatuses : null
      },
      authentication: Authentication.REQUIRED,
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    })
  }

  /**
   * @deprecated The method moved to 'orders' service
   */
  getDetailOrder(orderId: string): AxiosPromise<Orders.DetailedOrder.Order> {
    return this.get(`/order/${orderId}`, {
      authentication: Authentication.REQUIRED
    })
  }

  getRfqList(
    start: number,
    rows: number,
    search: string | null,
    startDate: string | null,
    endDate: string | null
  ): AxiosPromise<Rfq.Responses.RfqPagination> {
    return this.get('/rfq', {
      params: {
        start: start,
        rows: rows,
        search: search || null,
        startDate: startDate || null,
        endDate: endDate || null
      },
      authentication: Authentication.REQUIRED,
      cache: {
        tags: ['rfq']
      }
    })
  }

  getRfqDetail(
    rfqNegotiationId: string
  ): AxiosPromise<Rfq.RfqDetailed.DetailedRfqCart> {
    return this.get(`/rfq/temp/negotiation/${rfqNegotiationId}`, {
      authentication: Authentication.REQUIRED,
      cache: {
        tags: ['rfq']
      }
    })
  }

  getOfflineOrder(
    start: number,
    rows: number,
    search: string | null,
    startDate: string | null,
    endDate: string | null
  ): AxiosPromise<Orders.Responses.OrderPagination> {
    return this.get('/offline-order', {
      params: {
        start: start,
        rows: rows,
        search: search || null,
        startDate: startDate ? startDate : null,
        endDate: endDate ? endDate : null
      },
      authentication: Authentication.REQUIRED,
      cache: {
        tags: ['offline-order']
      }
    })
  }

  getSingleOfflineOrder(orderId: string): AxiosPromise<Orders.OfflineOrder> {
    return this.get(`/offline-order/single`, {
      authentication: Authentication.REQUIRED,
      params: {
        orderId
      },
      cache: {
        tags: ['offline-order']
      }
    })
  }

  /**
   * @deprecated The method moved to 'orders' service
   */
  getOrderStatuses(
    startDate: string | null,
    endDate: string | null,
    search: string | null
  ): AxiosPromise<Array<Orders.OrderStatusDto>> {
    return this.get('/order-status', {
      params: {
        startDate: startDate || null,
        endDate: endDate || null,
        search: search || null
      },
      authentication: Authentication.REQUIRED,
      cache: {
        tags: ['order-status']
      }
    })
  }

  /**
   * @deprecated The method moved to 'orders' service
   */
  payForOrder(
    orderId: string,
    callbackUrl: string
  ): AxiosPromise<Orders.Responses.PayForOrderResult> {
    return this.post(
      `/order/${orderId}/pay`,
      {
        callbackUrl
      },
      {
        authentication: Authentication.REQUIRED
      }
    )
  }

  /**
   * @deprecated The method moved to 'orders' service
   */
  exportOnlineOrder(orderId: string): AxiosPromise<BlobPart> {
    return this.get(`/order/${orderId}/export`, {
      authentication: Authentication.REQUIRED,
      responseType: 'blob'
    })
  }

  exportOfflineOrder(offlineOrderId: string): AxiosPromise<BlobPart> {
    return this.get('/offline-order/single/export', {
      params: { offlineOrderId },
      authentication: Authentication.REQUIRED,
      responseType: 'blob'
    })
  }

  exportRfq(rfqNegotiationId: string): AxiosPromise<BlobPart> {
    return this.get(`/rfq/temp/negotiation/${rfqNegotiationId}/export`, {
      authentication: Authentication.REQUIRED,
      responseType: 'blob'
    })
  }

  getOrderConfirmation(
    orderConfirmationId: string
  ): AxiosPromise<OrderConfirmation.Responses.OrderConfirmationCart> {
    return this.get(`/order-confirmation/${orderConfirmationId}`, {
      authentication: Authentication.REQUIRED
    })
  }

  submitUserOrder(
    orderConfirmationId: string,
    destinationTemplate: string
  ): AxiosPromise<OrderConfirmation.Responses.SubmitOrderResult> {
    this.invalidate(['carts', 'checkout'])
    return this.post(
      `/order-confirmation/${orderConfirmationId}/submit-order`,
      {
        destinationTemplate
      },
      {
        authentication: Authentication.REQUIRED
      }
    )
  }

  submitUserRfq(
    orderConfirmationId: string
  ): AxiosPromise<OrderConfirmation.Responses.RfqNegotiationId> {
    this.invalidate(['carts', 'checkout'])
    return this.post(
      `/order-confirmation/${orderConfirmationId}/submit-rfq`,
      null,
      {
        authentication: Authentication.REQUIRED
      }
    )
  }

  sendForAuthorization(
    destinationUserId: string,
    orderConfirmationId: string
  ): AxiosPromise<void> {
    this.invalidate(['carts', 'checkout'])
    return this.post(
      `/order-confirmation/${orderConfirmationId}/send-for-authorization`,
      {},
      {
        params: {
          destinationUserId
        },
        authentication: Authentication.REQUIRED
      }
    )
  }

  setOrderConfirmationComments(
    orderConfirmationId: string,
    notes: string
  ): AxiosPromise<void> {
    this.invalidate(['order-confirmation'])
    return this.patch(
      `/order-confirmation/${orderConfirmationId}/comments`,
      { notes },
      {
        authentication: Authentication.REQUIRED
      }
    )
  }

  getCheckout(
    checkoutId: string
  ): AxiosPromise<Checkout.Responses.CheckoutCart> {
    return this.get(`/checkout/${checkoutId}`, {
      authentication: Authentication.REQUIRED
    })
  }

  setAddress(
    checkoutId: string,
    shipmentId: string,
    addressId: Checkout.Requests.SetDeliveryAddressOption
  ): AxiosPromise<void> {
    return this.put(
      `/checkout/${checkoutId}/${shipmentId}/address/id`,
      addressId,
      {
        authentication: Authentication.REQUIRED
      }
    )
  }

  setTemporaryAddress(
    checkoutId: string,
    shipmentId: string,
    temporaryAddress: Checkout.Requests.TemporaryAddress
  ): AxiosPromise<void> {
    return this.put(
      `/checkout/${checkoutId}/${shipmentId}/address/temporary`,
      temporaryAddress,
      {
        authentication: Authentication.REQUIRED
      }
    )
  }

  removeAddress(checkoutId: string, shipmentId: string): AxiosPromise<void> {
    return this.delete(`/checkout/${checkoutId}/${shipmentId}/address`, {
      authentication: Authentication.REQUIRED
    })
  }

  getAvailableDeliveryDates(
    checkoutId: string,
    shipmentId: string
  ): AxiosPromise<Checkout.Responses.AvailableDeliveryDates> {
    return this.get(
      `/checkout/${checkoutId}/${shipmentId}/available-delivery-dates`,
      {
        authentication: Authentication.REQUIRED
      }
    )
  }

  setDeliveryDate(
    checkoutId: string,
    shipmentId: string,
    date: Checkout.Requests.SetDateOption
  ): AxiosPromise<void> {
    return this.put(
      `/checkout/${checkoutId}/${shipmentId}/delivery-date`,
      date,
      {
        authentication: Authentication.REQUIRED
      }
    )
  }

  removeDeliveryDate(
    checkoutId: string,
    shipmentId: string
  ): AxiosPromise<void> {
    return this.delete(`/checkout/${checkoutId}/${shipmentId}/delivery-date`, {
      authentication: Authentication.REQUIRED
    })
  }

  setAuthorizedUsers(
    checkoutId: string,
    body: Checkout.Requests.SetCheckoutAuthorizedUsersOption
  ): AxiosPromise<void> {
    return this.put(`/checkout/${checkoutId}/authorized-users`, body, {
      authentication: Authentication.REQUIRED
    })
  }

  createOrderConfirmation(checkoutId: string): AxiosPromise<void> {
    return this.post(`/checkout/${checkoutId}/order-confirmation`, null, {
      authentication: Authentication.REQUIRED
    })
  }

  setSystemComments(
    checkoutId: string,
    body: Checkout.Requests.SetSystemComment
  ): AxiosPromise<void> {
    return this.put(`/checkout/${checkoutId}/system-comments`, body, {
      authentication: Authentication.REQUIRED
    })
  }

  getCarts(
    warehouseId: string
  ): AxiosPromise<Array<Cart.Responses.BasicCartInfo>> {
    return this.get('/cart', {
      authentication: Authentication.REQUIRED,
      cache: {
        ttl: 60 * 1000,
        tags: ['carts']
      },
      params: {
        warehouseId
      }
    })
  }

  createCart(
    cartData: Cart.Requests.CreateNewCart
  ): AxiosPromise<Cart.Responses.NewCart> {
    this.invalidate(['carts'])
    return this.post('/cart', cartData, {
      authentication: Authentication.OPTIONAL
    })
  }

  getAnonymousCart(
    cartId: string,
    warehouseId: string
  ): AxiosPromise<Cart.Responses.Cart> {
    return this.get(`/cart/${cartId}`, {
      authentication: Authentication.OPTIONAL,
      injectToken: false,
      params: {
        warehouseId,
        splitChoice: null
      }
    })
  }

  getCart(
    cartId: string,
    warehouseId: string,
    splitChoice: string | null = null
  ): AxiosPromise<Cart.Responses.Cart> {
    return this.get(`/cart/${cartId}`, {
      authentication: Authentication.OPTIONAL,
      params: {
        warehouseId,
        splitChoice
      },
      cache: {
        tags: ['carts', 'cart']
      }
    })
  }

  removeAnonymousCart(cartId: string): AxiosPromise<void> {
    return this.delete(`/cart/${cartId}`, {
      authentication: Authentication.OPTIONAL,
      injectToken: false
    })
  }

  removeCart(cartId: string): AxiosPromise<void> {
    this.invalidate(['carts'])
    return this.delete(`/cart/${cartId}`, {
      authentication: Authentication.OPTIONAL
    })
  }

  renameCart(
    cartId: string,
    name: Cart.Requests.ChangeCartNameOption
  ): AxiosPromise<void> {
    this.invalidate(['carts'])
    return this.patch(`/cart/${cartId}/name`, name, {
      authentication: Authentication.OPTIONAL
    })
  }

  checkoutCart(
    cartId: string,
    warehouseId: string,
    splitChoice: string | null = null
  ): AxiosPromise<number> {
    this.invalidate(['carts'])
    return this.post(
      `/cart/${cartId}/checkout`,
      { warehouseId },
      {
        params: {
          splitChoice: splitChoice
        },
        authentication: Authentication.REQUIRED
      }
    )
  }

  setCartComments(
    cartId: string,
    comments: Cart.Requests.SetCartCommentsOption
  ): AxiosPromise<void> {
    this.invalidate(['cart'])
    return this.patch(`/cart/${cartId}/comments`, comments, {
      authentication: Authentication.OPTIONAL
    })
  }

  setClickAndCollectDeliveryMethod(
    cartId: string,
    shipmentId: string,
    data: DeliveryMethods.Requests.SetClickAndCollectDeliveryMethodOption
  ): AxiosPromise<void> {
    this.invalidate(['cart'])
    return this.patch(
      `/cart/${cartId}/shipment/${shipmentId}/delivery-method/click-and-collect`,
      data,
      {
        authentication: Authentication.OPTIONAL
      }
    )
  }

  setCourierDeliveryMethod(
    cartId: string,
    shipmentId: string,
    data: DeliveryMethods.Requests.SetCourierDeliveryMethodOption
  ): AxiosPromise<void> {
    this.invalidate(['cart'])
    return this.patch(
      `/cart/${cartId}/shipment/${shipmentId}/delivery-method/courier`,
      data,
      {
        authentication: Authentication.OPTIONAL
      }
    )
  }

  setCrossDockingDeliveryMethod(
    cartId: string,
    shipmentId: string,
    data: DeliveryMethods.Requests.SetCrossDockingDeliveryMethodOption
  ): AxiosPromise<void> {
    this.invalidate(['cart'])
    return this.patch(
      `/cart/${cartId}/shipment/${shipmentId}/delivery-method/cross-docking`,
      data,
      {
        authentication: Authentication.OPTIONAL
      }
    )
  }

  setParcelLockerDeliveryMethod(
    cartId: string,
    shipmentId: string,
    data: DeliveryMethods.Requests.SetParcelLockerDeliveryMethodOption
  ): AxiosPromise<void> {
    this.invalidate(['cart'])
    return this.patch(
      `/cart/${cartId}/shipment/${shipmentId}/delivery-method/parcel-locker`,
      data,
      {
        authentication: Authentication.OPTIONAL
      }
    )
  }

  setSplitChoice(cartId: string, splitChoice: string): AxiosPromise<void> {
    return this.patch(
      `/cart/${cartId}/split-choice`,
      {},
      {
        authentication: Authentication.OPTIONAL,
        params: {
          splitChoice
        }
      }
    )
  }

  removeDeliveryMethod(cartId: string): AxiosPromise<void> {
    this.invalidate(['cart'])
    return this.delete(`/cart/${cartId}/delivery-method`, {
      authentication: Authentication.OPTIONAL
    })
  }

  changeProductsOrder(
    cartId: string,
    newOrder: Cart.Requests.ChangeOrderOfProductsOption
  ): AxiosPromise<void> {
    this.invalidate(['cart'])
    return this.put(`/cart/${cartId}/order`, newOrder, {
      authentication: Authentication.OPTIONAL
    })
  }

  setPaymentMethod(
    cartId: string,
    paymentMethodId: Cart.Requests.SetPaymentMethodOption,
    warehouseId: string
  ): AxiosPromise<void> {
    this.invalidate(['cart'])
    return this.put(
      `/cart/${cartId}/payment-method`,
      { paymentMethodId },
      {
        authentication: Authentication.OPTIONAL,
        params: {
          warehouseId
        }
      }
    )
  }

  removePaymentFromCart(cartId: string): AxiosPromise<void> {
    this.invalidate(['cart'])
    return this.delete(`/cart/${cartId}/payment-method`, {
      authentication: Authentication.OPTIONAL
    })
  }

  addProduct(
    cartId: string,
    newProduct: Cart.Requests.AddProductToCart,
    warehouseId: string
  ): AxiosPromise<void> {
    this.invalidate(['carts'])
    return this.post(`/cart/${cartId}/products`, newProduct, {
      authentication: Authentication.OPTIONAL,
      params: {
        warehouseId
      }
    })
  }
  addSelectedProducts(
    cartId: string,
    newProducts: Array<Cart.Requests.AddProductToCart>,
    warehouseId: string
  ): AxiosPromise<void> {
    this.invalidate(['carts'])
    return this.put(`/cart/${cartId}/products-selected`, newProducts, {
      authentication: Authentication.OPTIONAL,
      params: {
        warehouseId
      }
    })
  }

  removeSelectedProducts(
    cartId: string,
    productIds: Array<Shared.ProductId>
  ): AxiosPromise<void> {
    this.invalidate(['carts'])
    return this.delete(`/cart/${cartId}/products-selected`, {
      authentication: Authentication.OPTIONAL,
      data: productIds
    })
  }

  // @deprecated
  changeProductAmount(
    cartId: string,
    newAmount: Cart.Requests.ChangeNumberOfProductInCart,
    warehouseId: string
  ): AxiosPromise<void> {
    this.invalidate(['carts'])
    return this.put(`/cart/${cartId}/products`, newAmount, {
      authentication: Authentication.OPTIONAL,
      params: {
        warehouseId
      }
    })
  }

  changeNumberOfProduct(
    cartId: string,
    warehouseId: string,
    payload: Cart.Requests.ChangeNumberOfProductInCart
  ): AxiosPromise<void> {
    this.invalidate(['carts'])
    return this.put(`/cart/${cartId}/products`, payload, {
      authentication: Authentication.OPTIONAL,
      params: {
        warehouseId
      }
    })
  }
  removeProduct(
    cartId: string,
    productId: Shared.ProductId
  ): AxiosPromise<void> {
    this.invalidate(['carts'])
    return this.delete(`/cart/${cartId}/products`, {
      data: {
        productId: productId.productId,
        productLineId: productId.productLineId
      },
      authentication: Authentication.OPTIONAL
    })
  }

  getDeliveryMethods(
    warehouseId: string,
    shipmentId: string,
    cartId: string
  ): AxiosPromise<Cart.Responses.DeliveryMethods> {
    return this.get(`/cart/${cartId}/shipment/${shipmentId}/delivery-method`, {
      authentication: Authentication.OPTIONAL,
      params: {
        warehouseId,
        shipmentId,
        cartId
      },
      cache: {
        tags: ['carts', 'cart', 'delivery_methods']
      }
    })
  }

  getPaymentMethods(
    cartId: string,
    warehouseId: string
  ): AxiosPromise<Array<PaymentMethod>> {
    return this.get(`/cart/${cartId}/payment-method`, {
      authentication: Authentication.OPTIONAL,
      params: {
        warehouseId
      }
    })
  }

  exportProductsFromCart(
    warehouseId: string,
    currencyCode: string,
    cartId: string
  ): AxiosPromise<string> {
    return this.get(`/cart/${cartId}/export`, {
      authentication: Authentication.OPTIONAL,
      cache: {
        tags: ['carts', 'cart', 'payment_methods']
      },
      responseType: 'blob',
      params: {
        currencyCode,
        warehouseId
      }
    })
  }

  importProductsFromCart(
    formData: FormData,
    cartId: string
  ): AxiosPromise<Cart.Responses.ParseError | []> {
    this.invalidate(['carts'])
    return this.post(`/cart/${cartId}/import`, formData, {
      authentication: Authentication.OPTIONAL
    })
  }

  takeoverCart(cartId: string): AxiosPromise<Cart.Responses.NewCart> {
    this.invalidate(['carts'])
    return this.post(
      `/cart/${cartId}/takeover`,
      {},
      {
        authentication: Authentication.REQUIRED
      }
    )
  }

  setPromoPoints(
    cartId: string,
    payload: EditPromoPointsRestCommand
  ): AxiosPromise<void> {
    return this.put(`/carts/${cartId}/promo-points`, payload, {
      authentication: Authentication.REQUIRED
    })
  }

  removePromoPoints(cartId: string): AxiosPromise<void> {
    return this.delete(`/carts/${cartId}/promo-points`, {
      authentication: Authentication.REQUIRED
    })
  }

  setCouponCodeCart(
    couponBody: Cart.Requests.SetCartCouponOption,
    cartId: string
  ): AxiosPromise<Cart.Responses.SetCartCouponDto> {
    this.invalidate(['cart'])
    return this.put(`/cart/${cartId}/coupon-code`, couponBody, {
      authentication: Authentication.OPTIONAL,
      params: {
        cartId
      }
    })
  }

  deleteCouponCode(cartId: string): AxiosPromise<void> {
    this.invalidate(['cart'])
    return this.delete(`/cart/${cartId}/coupon-code`, {
      authentication: Authentication.OPTIONAL
    })
  }

  getCampaignAwardsInCart(
    cartId: string,
    campaignId: string,
    warehouseId: string
  ): AxiosPromise<Cart.Responses.CampaignAwardsRestView> {
    return this.get(`carts/${cartId}/campaigns/${campaignId}/awards`, {
      authentication: Authentication.OPTIONAL,
      params: {
        warehouseId
      }
    })
  }

  getPaginatedAwardsForCart(
    cartId: string,
    campaignId: string,
    warehouseId: string,
    pageNumber?: number,
    pageSize?: number,
    productName?: string
  ): AxiosPromise<Cart.Responses.CampaignAwardsRestView> {
    return this.get(`carts/${cartId}/campaigns/${campaignId}/awards`, {
      authentication: Authentication.OPTIONAL,
      params: {
        warehouseId,
        pageNumber,
        pageSize,
        productName
      }
    })
  }

  getOffersList(
    pageNumber: number,
    pageSize: number,
    search?: string,
    status?: Shared.OfferStatusEnum,
    createdOnStartDate?: string,
    createdOnEndDate?: string,
    expirationDateStartDate?: string,
    expirationDateEndDate?: string,
    branchId?: string
  ): AxiosPromise<Offers.Responses.OffersList> {
    return this.get(`offers`, {
      authentication: Authentication.REQUIRED,
      params: {
        pageNumber,
        pageSize,
        search,
        status,
        createdOnStartDate,
        createdOnEndDate,
        expirationDateStartDate,
        expirationDateEndDate,
        branchId
      }
    })
  }

  getOfferPdf(
    offerId: string
  ): AxiosPromise<Offers.Responses.PrintedDocumentRestView> {
    return this.get(`/offers/${offerId}/print`, {
      authentication: Authentication.REQUIRED
    })
  }

  acceptOffer(offerId: string): AxiosPromise<void> {
    return this.put(
      `/offers/${offerId}/accept-offer`,
      {},
      {
        authentication: Authentication.REQUIRED
      }
    )
  }

  setWarehouseContextOnCart(
    cartId: string,
    warehouseId: string
  ): AxiosPromise<void> {
    return this.put(
      `/cart/${cartId}/context-warehouse`,
      {
        warehouseId
      },
      {
        authentication: Authentication.OPTIONAL
      }
    )
  }

  getManualCampaigns(
    cartId: string
  ): AxiosPromise<Orderpath.App.Shared.AvailableCampaignRestView> {
    return this.get(`/carts/${cartId}/campaigns/manually-selectable`, {
      authentication: Authentication.OPTIONAL
    })
  }

  updateManualCampaigns(
    cartId: string,
    campaigns: Array<string>
  ): AxiosPromise<void> {
    return this.put(
      `/carts/${cartId}/campaigns/manually-selectable`,
      { campaigns },
      {
        authentication: Authentication.OPTIONAL
      }
    )
  }
}
