import { ApiServiceWithSettings, Audience, Authentication } from '../../common'
import { AxiosPromise } from 'axios'
import { Account, Pagination } from '@one/types'
import qs from 'qs'
import ClientsRequestParameters = Account.Backoffice.Requests.ClientsRequestParameters
import { ClientSortingModeEnum } from '../../enums/ClientSortingModeEnum'
import Pager = Pagination.Pager
import { pickBy } from 'lodash-es'
import ContactPersonRestView = Account.Backoffice.ContactPersonRestView
import CreateOrUpdateContactPerson = Account.Backoffice.Requests.CreateOrUpdateContactPerson
import ContactPersonSearchParams = Account.Backoffice.Requests.ContactPersonSearchParams

export class AccountBackofficeApi extends ApiServiceWithSettings {
  getUsers(
    pageNumber: string,
    pageSize: string,
    clientId: string | undefined,
    query: string | null
  ): AxiosPromise<Pager<Account.Backoffice.Responses.UserWithOrganization>> {
    return this.get('/users', {
      params: {
        pageNumber,
        pageSize,
        clientId,
        query: query || null
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  getUserById(
    userId: string
  ): AxiosPromise<Account.Backoffice.Responses.UserWithOrganization> {
    return this.get(`/users/${userId}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  getClients(
    clientParameters: ClientsRequestParameters
  ): AxiosPromise<
    Pagination.Pager<Account.Backoffice.Responses.ClientRestView>
  > {
    return this.get('/clients', {
      params: {
        pageNumber: clientParameters.pageNumber,
        pageSize: clientParameters.pageSize,
        query: clientParameters.query,
        accountManagerId: clientParameters.accountManagerId,
        clientType: clientParameters.clientType,
        segmentId: clientParameters.segmentId,
        sortingMode:
          clientParameters.sortingMode ||
          ClientSortingModeEnum.INTERNAL_NICKNAME_ALPHABETICAL_ASC,
        warehouseId: clientParameters.warehouseId,
        operatorId: clientParameters.operatorId,
        branchId: clientParameters.branchId,
        additionalWarehouses: clientParameters.additionalWarehousesIds,
        blockadeTypes: clientParameters.blockadeTypes,
        createDateFrom: clientParameters.createDateFrom,
        createDateTo: clientParameters.createDateTo,
        clientStatus: clientParameters.clientStatus,
        additionalSegmentIds: clientParameters.additionalSegmentIds
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    })
  }

  getClientsByIds(
    clientIds: Array<string>
  ): AxiosPromise<Array<Account.Backoffice.Responses.ClientRestView>> {
    return this.get(`/clients-list`, {
      params: {
        clientIds
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    })
  }

  exportClientCSV(): AxiosPromise<string> {
    return this.get('/clients/export', {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getClientById(
    clientId: string
  ): AxiosPromise<Account.Backoffice.Responses.ClientRestView> {
    return this.get(`/clients/${clientId}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      cache: {
        ttl: 5000,
        tags: [`client-${clientId}`]
      }
    })
  }

  getClientDataById(
    clientId: string
  ): AxiosPromise<Account.Backoffice.Responses.ClientRestView> {
    return this.get(`/clients/${clientId}/data`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getBasicData(
    clientId: string
  ): AxiosPromise<Account.Backoffice.Responses.ClientBasicDataRestView> {
    return this.get(`/clients/${clientId}/basic-data`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  updateBasicData(
    clientId: string,
    basicData: Account.Backoffice.Requests.EditBasicClientDataCommand
  ): AxiosPromise<void> {
    return this.put(`/clients/${clientId}/basic-data`, basicData, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getAdvancedData(
    clientId: string
  ): AxiosPromise<Account.Backoffice.Responses.ClientAdvancedDataRestView> {
    return this.get(`/clients/${clientId}/advanced-data`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  updateAdvancedData(
    clientId: string,
    advancedData: Account.Backoffice.Requests.EditAdvancedClientDataCommand
  ): AxiosPromise<void> {
    return this.put(`/clients/${clientId}/advanced-data`, advancedData, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getFinanceData(
    clientId: string
  ): AxiosPromise<Account.Backoffice.Responses.ClientFinanceDataRestView> {
    return this.get(`/clients/${clientId}/finance`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  updateFinanceData(
    clientId: string,
    data: Account.Backoffice.Requests.EditClientFinanceDataCommand
  ): AxiosPromise<void> {
    return this.put(`/clients/${clientId}/finance-data`, data, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getSettingsData(
    clientId: string
  ): AxiosPromise<Account.Backoffice.Responses.ClientSettingsDto> {
    return this.get(`/clients/${clientId}/settings`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  updateSettingsData(
    clientId: string,
    settingsData: Account.Backoffice.Requests.EditClientSettingsCommand
  ): AxiosPromise<void> {
    return this.put(`/clients/${clientId}/settings`, settingsData, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getAdditionalData(
    clientId: string
  ): AxiosPromise<Account.Backoffice.Responses.AdditionalClientDataRestView> {
    return this.get(`/clients/${clientId}/additional-data`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  updateAdditionalData(
    clientId: string,
    additionalData: Account.Backoffice.Requests.EditAdditionalClientDataCommand
  ): AxiosPromise<void> {
    return this.put(`/clients/${clientId}/additional-data`, additionalData, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  createIndividualClient(
    data: Account.Backoffice.Requests.CreateIndividualClientBackofficeCommand
  ): AxiosPromise<{ id: string }> {
    return this.post('/individual-clients', data, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  createOrganization(
    data: Account.Backoffice.Requests.CreateOrganizationBackofficeCommand
  ): AxiosPromise<{ id: string }> {
    return this.post('/organizations', data, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  createUser(
    dataUser: Account.Backoffice.Requests.CreateUser
  ): AxiosPromise<void> {
    return this.post(`/users`, dataUser, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  deleteUser(idUser: string): AxiosPromise<void> {
    return this.delete(`/users/${idUser}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  updateUser(
    idUser: string,
    dataUser: Account.Backoffice.Requests.UpdateUser
  ): AxiosPromise<void> {
    return this.put(`/users/${idUser}`, dataUser, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getAgreements(): AxiosPromise<Array<Account.Backoffice.AgreementTemplate>> {
    return this.get('/agreements', {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      cache: {
        ttl: 60 * 100,
        tags: ['agreements']
      }
    })
  }

  createAgreement(
    data: Account.Backoffice.CreateAgreementOption
  ): AxiosPromise<void> {
    this.invalidate(['agreements'])
    return this.post('/agreements', data, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  publishAgreement(agreementTypeId: string): AxiosPromise<void> {
    this.invalidate(['agreements'])
    return this.put(
      `/agreements/${agreementTypeId}/publish`,
      {},
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  unpublishAgreement(agreementTypeId: string): AxiosPromise<void> {
    this.invalidate(['agreements'])
    return this.put(
      `/agreements/${agreementTypeId}/unpublish`,
      {},
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  getAgreementsType(): AxiosPromise<
    Array<Account.Backoffice.AgreementType.Agreement>
  > {
    return this.get('/agreement-types', {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  createAgreementsType(
    request: Account.Backoffice.AgreementType.CreateAgreement
  ): AxiosPromise<void> {
    return this.post('/agreement-types', request, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  updateAgreementsType(
    agreementTypeId: string,
    request: Account.Backoffice.AgreementType.UpdateAgreement
  ): AxiosPromise<void> {
    return this.put(`/agreement-types/${agreementTypeId}`, request, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  deleteAgreementsType(agreementTypeId: string): AxiosPromise<void> {
    return this.delete(`/agreement-types/${agreementTypeId}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getAddresses(
    pageNumber: number,
    pageSize: number,
    clientId: string
  ): AxiosPromise<
    Pagination.Pager<Account.Backoffice.Responses.AddressBackoffice>
  > {
    return this.get('/addresses', {
      params: {
        pageNumber,
        pageSize,
        clientId
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  createAddresses(
    data: Account.Backoffice.Requests.CreateAddressBackofficeOption,
    clientId: string
  ): AxiosPromise<void> {
    return this.post(`/addresses?clientId=${clientId}`, data, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  deleteAddresses(addressId: string): AxiosPromise<void> {
    return this.delete(`/addresses/${addressId}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getTradeCreditByClient(
    clientId: string
  ): AxiosPromise<Account.Backoffice.Responses.TradeCredit> {
    return this.get(`/trade-credit/${clientId}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      cache: {
        tags: ['client-trade-credit']
      }
    })
  }

  getSegments(
    pageNumber: number,
    pageSize: number
  ): AxiosPromise<Array<Account.Backoffice.Responses.Segment>> {
    return this.get('/segments', {
      params: {
        pageNumber,
        pageSize
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  createSegment(
    data: Account.Backoffice.Requests.CreateSegmentOption
  ): AxiosPromise<void> {
    return this.post(`/segments`, data, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  getSegment(
    segmentId: string
  ): AxiosPromise<Account.Backoffice.Responses.Segment> {
    return this.get(`/segments/${segmentId}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  updateSegment(
    segmentId: string,
    data: Account.Backoffice.Requests.EditSegmentOption
  ): AxiosPromise<void> {
    return this.put(`/segments/${segmentId}`, data, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  deleteSegment(segmentId: string): AxiosPromise<void> {
    return this.delete(`/segments/${segmentId}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  getTermPayments(
    pageNumber: string,
    pageSize: string
  ): AxiosPromise<Account.Backoffice.Responses.TermPaymentList> {
    return this.get('/term-payments', {
      params: {
        pageNumber,
        pageSize
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  createTermPayment(
    data: Account.Backoffice.Requests.CreateTermPaymentOptionDto
  ): AxiosPromise<void> {
    return this.post(`/term-payments`, data, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  getTermPayment(
    termPaymentId: string
  ): AxiosPromise<Account.Backoffice.TermPaymentRestView> {
    return this.get(`/term-payments/${termPaymentId}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  updateTermPayment(
    termPaymentId: string,
    data: Account.Backoffice.Requests.EditTermPaymentOptionDto
  ): AxiosPromise<void> {
    return this.put(`/term-payments/${termPaymentId}`, data, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  deleteTermPayment(termPaymentId: string): AxiosPromise<void> {
    return this.delete(`/term-payments/${termPaymentId}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  getAccountManagers(
    params: Account.Backoffice.Requests.AccountManagerQuery
  ): AxiosPromise<
    Pagination.Pager<Account.Backoffice.Responses.AccountManager>
  > {
    return this.get('/account-manager', {
      params,
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getAccountManager(
    accountManagerId: string
  ): AxiosPromise<Account.Backoffice.Responses.AccountManager> {
    return this.get(`/account-manager/${accountManagerId}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  getAccountBlockades(
    pageNumber: string,
    pageSize: string
  ): AxiosPromise<
    Pagination.Pager<Account.Backoffice.Responses.AccountBlockade>
  > {
    return this.get('/blockades', {
      params: {
        pageNumber,
        pageSize
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  createAccountBlockade(
    newBlockade: Account.Backoffice.Requests.AccountBlockadeForm
  ): AxiosPromise<void> {
    return this.post('/blockades', newBlockade, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  getAccountBlockade(
    id: string
  ): AxiosPromise<Account.Backoffice.Responses.AccountBlockade> {
    return this.get(`/blockades/${id}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  updateAccountBlockade(
    id: string,
    blockadeFields: Account.Backoffice.Requests.AccountBlockadeForm
  ): AxiosPromise<void> {
    return this.put(`/blockades/${id}`, blockadeFields, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  deleteAccountBlockade(id: string): AxiosPromise<void> {
    return this.delete(`/blockades/${id}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  getAccountBlockadeForClient(
    clientId: string
  ): AxiosPromise<Account.Backoffice.Responses.AccountBlockade> {
    return this.get(`/client-blockades/${clientId}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  putAccountBlockadeToClient(
    clientId: string,
    blockadeMarker: { id: string }
  ): AxiosPromise<void> {
    return this.put(`/client-blockades/${clientId}`, blockadeMarker, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  deleteAccountBlockadeForClient(clientId: string): AxiosPromise<void> {
    return this.delete(`/client-blockades/${clientId}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  getTaxpayerAddress(
    tin: string
  ): AxiosPromise<Account.Backoffice.BillingAddress> {
    return this.get(`/taxpayer/address?tin=${tin}`, {
      authentication: Authentication.PUBLIC,
      aud: Audience.BACKOFFICE
    })
  }

  getAdditionalFields(): AxiosPromise<Account.Backoffice.AdditionalFields> {
    return this.get(`/configurations/additional-fields`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  postAdditionalFields(
    data: any
  ): AxiosPromise<Account.Backoffice.AdditionalFields> {
    return this.put('/configurations/additional-fields', data, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  getAdditionalFieldsVisible(
    viewName: string
  ): AxiosPromise<Account.Backoffice.AdditionalFields> {
    return this.get(
      `/configurations/additional-fields/visible?viewName=${viewName}`,
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }
  getTinServiceVisibility(): AxiosPromise<
    Account.Backoffice.Responses.TinServiceFormField
  > {
    return this.get('/additional-settings/tin-service', {
      authentication: Authentication.PUBLIC,
      aud: Audience.BACKOFFICE
    })
  }

  removeClient(clientId: string): AxiosPromise<void> {
    return this.delete(`/clients/${clientId}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  fetchContacts(
    searchParams: ContactPersonSearchParams
  ): AxiosPromise<Pager<ContactPersonRestView>> {
    return this.get('/contacts', {
      params: pickBy({ ...searchParams }),
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  createContactPerson(form: CreateOrUpdateContactPerson): AxiosPromise<void> {
    return this.post('/contacts', form, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getContactPerson(
    contactPersonId: string
  ): AxiosPromise<Account.Backoffice.ContactPersonRestView> {
    return this.get(`/contacts/${contactPersonId}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  updateContactPerson(
    contactPersonId: string,
    form: CreateOrUpdateContactPerson
  ) {
    return this.put(`/contacts/${contactPersonId}`, form, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  deleteContactPerson(contactPersonId: string): AxiosPromise<void> {
    return this.delete(`/contacts/${contactPersonId}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  createLead(
    form: Account.Backoffice.Requests.CreateOrUpdateLeadCommand
  ): AxiosPromise<void> {
    return this.post('/leads', form, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getLeads(
    searchParams: Account.Backoffice.Requests.SearchLeadsParams
  ): AxiosPromise<Pagination.Pager<Account.Backoffice.LeadRestView>> {
    return this.get(`/leads`, {
      params: pickBy({ ...searchParams }),
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getLeadById(leadId: string): AxiosPromise<Account.Backoffice.LeadRestView> {
    return this.get(`/leads/${leadId}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  updateLead(
    leadId: string,
    form: Account.Backoffice.Requests.CreateOrUpdateLeadCommand
  ): AxiosPromise<void> {
    return this.put(`/leads/${leadId}`, form, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  deleteLead(leadId: string): AxiosPromise<void> {
    return this.delete(`/leads/${leadId}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  convertLeadToClient(leadId: string): AxiosPromise<void> {
    return this.post(
      `/leads/${leadId}/to-client`,
      {},
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }
}
