import { ApiServiceWithSettings, Audience, Authentication } from '../../common'
import { Account, Auth, Pagination } from '@one/types'
import { AxiosPromise } from 'axios'
import Pager = Pagination.Pager

export class BackofficeAuthApi extends ApiServiceWithSettings {
  login(data: Auth.Requests.Login): AxiosPromise<Auth.Responses.Token> {
    return this.post('/session/login', data, {
      aud: Audience.BACKOFFICE
    })
  }

  tokenRefresh(
    data: Auth.Requests.RefreshToken
  ): AxiosPromise<Auth.Responses.Token> {
    return this.post(`/session/token`, data, {
      aud: Audience.BACKOFFICE
    })
  }

  resetPassword(data: Auth.Requests.ResetPassword): AxiosPromise<void> {
    return this.post(`/session/password/reset`, data, {
      aud: Audience.BACKOFFICE,
      authentication: Authentication.PUBLIC
    })
  }

  setPassword(
    data: Auth.Requests.SetPassword,
    token: string
  ): AxiosPromise<void> {
    return this.put('/session/password', data, {
      aud: Audience.BACKOFFICE,
      authentication: Authentication.REQUIRED,
      injectToken: false,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  }

  /**
   * Returns base64
   *
   * @param totpToken
   */
  totpSetup(
    totpToken: string
  ): AxiosPromise<Auth.Backoffice.Responses.TotpSetup> {
    return this.post(
      '/session/totp/setup',
      { totpToken },
      {
        aud: Audience.BACKOFFICE
      }
    )
  }

  totpLogin(
    totpToken: string,
    code: string
  ): AxiosPromise<Auth.Backoffice.Responses.TotpToken> {
    return this.post(
      '/session/totp/login',
      { totpToken, code },
      {
        aud: Audience.BACKOFFICE
      }
    )
  }

  createOperationUser(
    data: Auth.Backoffice.Request.CreateOperatorOption
  ): AxiosPromise<void> {
    return this.post('/operators', data, {
      aud: Audience.BACKOFFICE,
      authentication: Authentication.REQUIRED
    })
  }

  editOperationUser(
    data: Auth.Backoffice.Request.EditOperatorOption,
    operatorId: string
  ): AxiosPromise<void> {
    return this.put(`/operators/${operatorId}`, data, {
      aud: Audience.BACKOFFICE,
      authentication: Authentication.REQUIRED
    })
  }

  getOperationUsers(
    isActive?: boolean,
    nameOrEmail?: string,
    pageNumber?: number,
    pageSize?: number,
    roleName?: string
  ): AxiosPromise<Pager<Auth.Backoffice.Responses.Operator>> {
    return this.get('/operators', {
      params: {
        isActive: typeof isActive === 'boolean' ? isActive : null,
        nameOrEmail: nameOrEmail || undefined,
        pageNumber: pageNumber || 1,
        pageSize: pageSize || 999,
        roleName: roleName || undefined
      },
      aud: Audience.BACKOFFICE,
      authentication: Authentication.REQUIRED
    })
  }

  getOperationUser(
    operatorId: string
  ): AxiosPromise<Auth.Backoffice.Responses.SimpleOperator> {
    return this.get(`/operator/${operatorId}`, {
      aud: Audience.BACKOFFICE,
      authentication: Authentication.REQUIRED
    })
  }

  applicationRoleList(
    clientId: string
  ): AxiosPromise<Auth.Backoffice.Responses.ApplicationRole> {
    return this.get('/roles', {
      params: {
        clientId
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  assignBackofficeRole(
    req: Auth.Backoffice.Request.AssignToBackofficeRoleCommand
  ): AxiosPromise<string> {
    return this.post('/backoffice-role-assignments', req, {
      aud: Audience.BACKOFFICE,
      authentication: Authentication.REQUIRED
    })
  }

  backofficeRoleList(
    pageNumber: string,
    pageSize: string
  ): AxiosPromise<
    Pagination.Pager<Auth.Backoffice.Responses.BackofficeRoleListRestView>
  > {
    return this.get('/backoffice-roles', {
      params: {
        pageNumber,
        pageSize
      },
      aud: Audience.BACKOFFICE,
      authentication: Authentication.REQUIRED
    })
  }

  createBackofficeRole(
    req: Auth.Backoffice.Request.CreateBackofficeRoleCommand
  ): AxiosPromise<string> {
    return this.post('/backoffice-roles', req, {
      aud: Audience.BACKOFFICE,
      authentication: Authentication.REQUIRED
    })
  }

  singleBackofficeRole(
    id: string
  ): AxiosPromise<Auth.Backoffice.Responses.BackofficeRoleRestView> {
    return this.get(`/backoffice-roles/${id}`, {
      aud: Audience.BACKOFFICE,
      authentication: Authentication.REQUIRED
    })
  }

  deleteBackofficeRole(id: string): AxiosPromise<string> {
    return this.delete(`/backoffice-roles/${id}`, {
      aud: Audience.BACKOFFICE,
      authentication: Authentication.REQUIRED
    })
  }

  updateBackofficeRole(
    id: string,
    req: Auth.Backoffice.Request.UpdateBackofficeRoleCommand
  ): AxiosPromise<string> {
    return this.patch(`/backoffice-roles/${id}`, req, {
      aud: Audience.BACKOFFICE,
      authentication: Authentication.REQUIRED
    })
  }
}
